import React from "react";
import {
  chakra,
  Flex,
  HStack,
  IconButton,
  Link,
  useColorModeValue,
  useDisclosure,
  CloseButton,
 
  VStack,
  Button,
  Spacer
} from "@chakra-ui/react";

import {useViewportScroll } from "framer-motion"
import {
  AiOutlineMenu,
  AiFillHome,
  AiOutlineInbox,
} from "react-icons/ai";

import lgo from "./Logo.png";



function Header()  {
    
    
    const bg = useColorModeValue("white", "gray.800");
    const ref = React.useRef(null);
    const [y, setY] = React.useState(0);
    const height = ref.current ? ref.current.getBoundingClientRect() : 0;
    const { scrollY } = useViewportScroll();
    React.useEffect(() => {
      return scrollY.onChange(() => setY(scrollY.get()));
    }, [scrollY]);
    const cl = useColorModeValue("gray.800", "white");
    const mobileNav = useDisclosure();
 

    const MobileNavContent = (
      <VStack
        pos="absolute"
        top={0}
        left={0}
        right={0}
        display={mobileNav.isOpen ? "flex" : "none"}
        flexDirection="column"
        p={2}
        pb={4}
        m={2}
        bg={bg}
        spacing={3}
        rounded="sm"
        shadow="sm"
      >
        <CloseButton
          aria-label="Close menu"
          justifySelf="self-start"
          onClick={mobileNav.onClose}
        />

        
        <a href="/">
        <Button w="full" variant="ghost" leftIcon={<AiFillHome />}>
          Home
        </Button></a>


        <a href="/dmca">
        <Button w="full" variant="ghost" leftIcon={<AiFillHome />}>
          DMCA
        </Button></a>


        <a href="/api"><Button
          w="full"
          variant="solid"
          leftIcon={<AiOutlineInbox />}
        >
          API access
        </Button>
        </a>


        <a href="/contact">
        <Button w="full" variant="ghost" colorScheme="brand" leftIcon={<AiOutlineInbox />}>
          Contact us
        </Button></a>
      </VStack>
    );
    return (
      <React.Fragment>
        <chakra.header
          ref={ref}
          shadow={y > height ? "sm" : undefined}
          transition="box-shadow 0.2s"
          bg={bg}
          borderTop="6px solid"
          borderTopColor="brand.400"
          w="full"
          overflowY="hidden"
        >
          <chakra.div h="4.5rem" mx="auto" maxW="1200px">
            <Flex
              w="full"
              h="full"
              px="6"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex align="flex-start">
                <Link href="/">
                  <HStack>
                  <img src={lgo} alt="" height={60} width={260}/>

                  </HStack>
                </Link>
              </Flex>
              <Flex>
                <HStack
                  spacing="5"
                  display={{
                    base: "none",
                    md: "flex",
                  }}
                >

                   <a href="/">
                  <Button
                    bg={bg}
                    color="gray.500"
                    display="inline-flex"
                    alignItems="center"
                    fontSize="md"
                    _hover={{
                      color: cl,
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    Home
                  </Button></a>
              <a href="/dmca">
                  <Button
                    bg={bg}
                    color="gray.500"
                    display="inline-flex"
                    alignItems="center"
                    fontSize="md"
                    _hover={{
                      color: cl,
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    DMCA
                  </Button>
                  </a>
              
                </HStack>
              </Flex>
              <Spacer />
              <Flex justify="flex-end" align="center" color="gray.400">
                <HStack
                  spacing="5"
                  display={{
                    base: "none",
                    md: "flex",
                  }}
                >
                 <a href="/api">
                  <Button colorScheme="brand" variant="ghost" size="sm">
                    API Access
                  </Button></a>
                 <a href="/contact"> <Button colorScheme="brand" variant="solid" size="sm">
                    Contact Us
                  </Button> </a>
                </HStack>
                
                <IconButton
                  display={{
                    base: "flex",
                    md: "none",
                  }}
                  aria-label="Open menu"
                  fontSize="20px"
                  color="gray.800"
                  _dark={{
                    color: "inherit",
                  }}
                  variant="ghost"
                  icon={<AiOutlineMenu />}
                  onClick={mobileNav.onOpen}
                />
              </Flex>
            </Flex>
            {MobileNavContent}
          </chakra.div>
        </chakra.header>
      </React.Fragment>
    );
  };

  export default Header;
