import React from "react";
import {
  chakra,
  Flex,
  Box,
Icon,Stack
} from "@chakra-ui/react";


import { DownloadIcon,NotAllowedIcon,CheckCircleIcon } from '@chakra-ui/icons'



function Features() {




    const Feature = (props) => {
      return (
        <Flex>
          <Flex shrink={0}>
            <Flex
              alignItems="center"
              justifyContent="center"
              h={12}
              w={12}
              rounded="md"
              _light={{
                bg: "brand.500",
              }}
              color="white"
            >
              <Icon
                boxSize={6}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                {props.icon}
              </Icon>
            </Flex>
          </Flex>
          <Box ml={4}>
            <chakra.dt
              fontSize="lg"
              fontWeight="medium"
              lineHeight="6"
              _light={{
                color: "gray.900",
              }}
            >
              {props.title}
            </chakra.dt>
            <chakra.dd
              mt={2}
              color="gray.500"
              _dark={{
                color: "gray.400",
              }}
            >
              {props.children}
            </chakra.dd>
          </Box>
        </Flex>
      );
    };
    return (
      <Flex
        bg="#edf3f8"
        _dark={{
          bg: "#3e3e3e",
        }}
        p={20}
        w="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          py={12}
          bg="white"
          _dark={{
            bg: "gray.800",
          }}
          rounded="xl"
        >
          <Box
            maxW="7xl"
            mx="auto"
            px={{
              base: 4,
              lg: 8,
            }}
          >
            <Box
              textAlign={{
                lg: "center",
              }}
            >
              <chakra.h2
                _light={{
                  color: "brand.600",
                }}
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Our Features
              </chakra.h2>
              <chakra.p
                mt={2}
                fontSize={{
                  base: "3xl",
                  sm: "4xl",
                }}
                lineHeight="8"
                fontWeight="extrabold"
                letterSpacing="tight"
                _light={{
                  color: "gray.900",
                }}
              >
               Premium Youtube Video Downloader
              </chakra.p>
              <chakra.p
                mt={4}
                maxW="2xl"
                fontSize="xl"
                mx={{
                  lg: "auto",
                }}
                color="gray.500"
                _dark={{
                  color: "gray.400",
                }}
              >
                Stop wasting your time on downloader that requires chrome  extensions, surveys, spam ads. 
              </chakra.p>
            </Box>
  
            <Box mt={10}>
              <Stack
                spacing={{
                  base: 10,
                  md: 0,
                }}
                display={{
                  md: "grid",
                }}
                gridTemplateColumns={{
                  md: "repeat(2,1fr)",
                }}
                gridColumnGap={{
                  md: 8,
                }}
                gridRowGap={{
                  md: 10,
                }}
              >
                <Feature
                  title="Unlimited Video Downloads"
                  icon={
                    <DownloadIcon/>
                  }
                >
                  You can download as many videos as you want from youtube no restiction from our side. Our website supports downloading videos  to android, iphone, laptop and desktop. 
                </Feature>
  
                <Feature
                  title="Multiple Quality Options"
                  icon={
                    <CheckCircleIcon/>
                  }
                >
                  We have different quality options avilable like 480, 720p, 1080p and 4k. Download in  your prefered quality with just one click.
                </Feature>
  
                <Feature
                  title="Multiple Format & Frame Rate Support"
                  icon={
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  }
                >
                  Our platform support multiple video and audio formats like mp4,webm, mp3. We also support different frame rates like 30,60,144hz if the video has it. 
                </Feature>
  
                <Feature
                  title="No Additional Software/Extension Required"
                  icon={
                    <NotAllowedIcon/>
                  }
                >
                Are are frustrated with the downloaders that requires additonal software or extenstion? With us you dont need  additional extension, just simply download from our website safely.
                </Feature>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>
    );
  };
  

export default Features