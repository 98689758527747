import Header from "./header";
import Footer from "./footer";

function DMCA(){


    return (
        <>
        <Header/>
        <Footer/>
        </>
    )


}

export default DMCA