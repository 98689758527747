import Header from "./header";
import Downloader from "./downloader";
import Footer from "./footer";
import Features from "./features";



function Home() {

  
    return (
     <>
     <Header/>
     <Downloader/>
     <Features/>
     <Footer/>
     
     </>
     
        
        
    );
  }
  
  export default Home;