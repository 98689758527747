import React from "react";
import {
  chakra,
  Flex
} from "@chakra-ui/react";




function Footer(){
    return(
    
    <Flex
    
    bg="white"
    _dark={{
      bg: "#3e3e3e",
    }}
    p={2}
    alignItems="center"
    justifyContent="center" border='1px' borderColor='gray.200'
   >
  
      <chakra.p
        color="gray.600"
        _dark={{
          color: "white",
        }}
        fontWeight={"medium"}
      >
       Copyright 2024 All rights reserved
      </chakra.p>
  
    
  </Flex>)
}

export default  Footer