import Header from "./header";
import Footer from "./footer";



import React from "react";
import {
  chakra,
  Box,

  Button,
 
} from "@chakra-ui/react";


import { Container } from '@chakra-ui/react'

function API(){

 
   

    return (
        <>
        <Container maxW='7xl' pt={5} minHeight="100vh">
        <Header/>
     
      
  
      <Box px={14} py={10}>
            <chakra.h2
              mb={3}
              fontSize={{
                base: "xl",
                md: "2xl",
              }}
              fontWeight="extrabold"
              textAlign={{
                base: "center",
                sm: "left",
              }}
              _light={{
                color: "black",
              }}
              lineHeight="shorter"
              letterSpacing="tight"
            >
              Get Access to our api
            </chakra.h2>
            <chakra.p
              mb={6}
              fontSize={{
                base: "lg",
                md: "xl",
              }}
              textAlign={{
                base: "center",
                sm: "left",
              }}
              color="gray.500"
              _dark={{
                color: "gray.500",
              }}
            >
           Our api supports downloading fromYoutube with an easy api interface.
      Feel free to reach reach us at shaonreverse@gmail.com to get access to our api 

            </chakra.p>
            <a href="/contact"><Button
              as="a"
              variant="solid"
              w={{
                base: "full",
                sm: "auto",
              }}
              colorScheme="brand"
              size="md"
            >
              Contact us 
            </Button>
            </a>
          </Box>


  
        <Footer/>
        </Container>
        </>
    )


}

export default API