
import './App.css';
import Home from './components/Home';
import {createBrowserRouter,RouterProvider} from "react-router-dom"
import DMCA from './components/dmca';
import ContactUs from './components/contact';
import API from './components/api';
function App() {

  const router = createBrowserRouter([

    {
      path:"/",
      element:<Home/>
    },
    {
      path:"/dmca",
      element:<DMCA/>
    },
    {
      path:"/contact",
      element:<ContactUs/>
    }
    ,
    {
      path:"/api",
      element:<API/>
    }


  ])
  return (
   <>
  
   <RouterProvider router={router}/>
   </>
   
      
      
  );
}

export default App;
