import React from "react";
import {
  chakra,
  Flex,
  Box,
  Button,SimpleGrid,Icon,GridItem,VisuallyHidden,Input,Table,Tbody,Thead,TableContainer,Tr,Td,Th,Spinner,useBreakpointValue 
} from "@chakra-ui/react";



import { useState } from "react";
import { BsLightningFill } from "react-icons/bs";
import axios from 'axios';

function Downloader() {
  

    const [ytLink, setytLink] = useState("")
    const [error, setError] = useState('')
    const [isLoading, setisLoading] = useState(false)
    const [isFetched, setisFetched] = useState(false)
    const [data, setData] = useState([]);

    const tableSize = useBreakpointValue({ base: 'sm', md: 'md' });

    const handleDownload = (url) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = ''; // Forces the browser to download instead of open
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up the DOM
    };


    function get_youtube_thumbnail(url, quality) {
      if (url) {
          var video_id, result;
  
          // Use comparison operator (===) instead of assignment (=)
          result = url.match(/youtube\.com.*(?:\?v=|\/embed\/)(.{11})/);
          if (result && result[1]) {
              video_id = result[1]; // Extract video ID
          } else if (result = url.match(/youtu.be\/(.{11})/)) {
              video_id = result[1]; // Extract video ID
          }
  
          // If video_id is found, construct the thumbnail URL
          if (video_id) {
              if (typeof quality === "undefined") {
                  quality = 'high';
              }
  
              // Choose thumbnail quality based on input
              var quality_key = 'maxresdefault'; // Max quality by default
              if (quality === 'low') {
                  quality_key = 'sddefault';
              } else if (quality === 'medium') {
                  quality_key = 'mqdefault';
              } else if (quality === 'high') {
                  quality_key = 'hqdefault';
              }
  
              // Construct and return the thumbnail URL
              var thumbnail = "https://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
              return thumbnail;
          }
      }
      return false;
  }


  function codec_extractor(codecString){
const codec = codecString.split('.')[0];
return codec
  }




  function res__extractor(FrameString){

    let res = ""

    if (FrameString==="854x480"){
      res = "480p"
    }
    if (FrameString==="1280x720"){
      res = "720p"
    }
    if (FrameString==="1920x1080"){
      res = "1080p"
    }
    if (FrameString==="3840x2160"){
      res = "4k (3840x2160)"
    }
    if (FrameString==="4096x2160"){
      res = "4k (4096x2160)"
    }
    if (FrameString==="audio only"){
      res = "Audio"
    }

    return res;
    

  }
    

    const ytDownBtnClick = async()=>{
      setisLoading(true)

        console.log(`pressed button download link ${ytLink}`)

        const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/

        if (!ytLink) {
         setError('Please enter a YouTube link.')
         setisLoading(false)
         setisFetched(false)
         return;
         }

         if (!youtubeRegex.test(ytLink)) {
           setError('Invalid YouTube URL.')
           
           setisLoading(false)
           setisFetched(false)
           return;
         }

         try {
          const response = await axios.get('https://ytserver-cfnp.onrender.com/get-formats', {
            params: { url: ytLink }, 
          });
    
          if (response.data.error) {
            setError(response.data.message)
            setisLoading(false)
            setisFetched(false)
            return;
          } else {
            setData(response.data)
            setisLoading(false)
            setisFetched(true)
            
          }
        } 
        
        catch (err) {
          setError('Error fetching formats. Please recheck the video url.')
          setisLoading(false)
          setisFetched(false)
          return ;
        }
      

        
        setError('')

    }

  const DownloadInfo = (props)=>{
    const thumbnail = get_youtube_thumbnail(ytLink, 'medium')
    
    return (
    

<>
    <Flex
 
  _dark={{
    bg: "#3e3e3e",
  }}
  
  w="full"
  alignItems="center"
  justifyContent="center"
  pt={8}
  pb={2}
>
  <Box
    bg="white"
    w="full"
    _dark={{
      bg: "gray.800",
    }}
   
    display={{
      lg: "flex",
    }}
    
    
    shadow={{
      lg: "lg",
    }}
    rounded={{
      lg: "lg",
    }}
  >
    <Box
      w={{
        lg: "50%",
      }}
    >
      <Box
        h={{
          base: 124,
          lg: "full",
        }}
        rounded={{
          lg: "lg",
        }}
        bgSize="cover"
        style={{
          backgroundImage:
            `url('${thumbnail}')`,
        }}
      ></Box>
    </Box>

    <Box
      py={12}
      px={6}
      maxW={{
        base: "xl",
        lg: "5xl",
      }}
      w={{
        lg: "50%",
      }}
    >
      <chakra.h2
        fontSize={{
          base: "2xl",
          md: "3xl",
        }}
        color="gray.800"
        _dark={{
          color: "white",
        }}
        fontWeight="bold"
      >
        {data.title}
       
      </chakra.h2>
      <chakra.p
        mt={4}
        color="gray.600"
        _dark={{
          color: "gray.400",
        }}
      >
        {data.description}
      </chakra.p>

  
    </Box>
  </Box>
</Flex>
<TableContainer background={"#FFFFFF"} >
  <Table size={tableSize}  >
    <Thead>
      <Tr>
       <Th display={{ base: 'none', md: 'table-cell' }} >Format</Th>
        <Th>Quality</Th>
        <Th isNumeric display={{ base: 'none', md: 'table-cell' }}>Frame Rate</Th>
        <Th display={{ base: 'none', md: 'table-cell' }}>Codec</Th>
        <Th display={{ base: 'none', md: 'table-cell' }}>Bit Rate</Th>
        <Th>Size</Th>
        <Th>Download Link</Th>
        
      </Tr>
    </Thead>
    <Tbody>
  {data.formats.length > 0 ? (
    data.formats.map((format, index) => (
      <Tr key={index}>
        <Td display={{ base: 'none', md: 'table-cell' }}>{format.extension}</Td>
        <Td>{res__extractor(format.resolution)}</Td>
        <Td isNumeric display={{ base: 'none', md: 'table-cell' }}>{format.fps}</Td>
        <Td display={{ base: 'none', md: 'table-cell' }}>{codec_extractor(format.codec)}</Td>
        <Td display={{ base: 'none', md: 'table-cell' }}>{format.tbr}</Td>
        <Td>{format.filesize}</Td>
        <Td>
         
            <Button
              as={GridItem}
              w="1/2"
              variant="solid"
              colSpan={{
                base: "auto",
                lg: 2,
              }}
              size={tableSize}
              type="submit"
              colorScheme="brand"
              cursor="pointer"
              onClick={() => handleDownload(format.download_link)}
            >
              Download
            </Button>
         
        </Td>
      </Tr>
    ))
  ) : (
    <Tr>
      <Td colSpan={5} textAlign="center">No formats available</Td>
    </Tr>
  )}
</Tbody>

  </Table>
</TableContainer>
</>


  )
  }
  const Loading =()=>{

    if(isLoading){
      return (<Spinner color='white' size="lg" />)
    }

    if (error){
      return (<Warning/>)}
    if(!error  && isFetched===true){
      return(<DownloadInfo/>)
    }
     

  }
  const Warning = (props)=>
    (
  
  <Flex
    maxW="3xl"
    w="full"
    mx="auto"
    bg="white"
    _dark={{
      bg: "gray.800",
    }}
    shadow="md"
    rounded="lg"
    overflow="hidden"
  >
    <Flex justifyContent="center" alignItems="center" w={12} bg="red.500">
      <Icon as={BsLightningFill} color="white" boxSize={6} />
    </Flex>

    <Box mx={-3} py={2} px={4}>
      <Box mx={3}>
        <chakra.span
          color="red.500"
          _dark={{
            color: "red.400",
          }}
          fontWeight="bold"
        >
          Error
        </chakra.span>
        <chakra.p
          color="gray.600"
          _dark={{
            color: "gray.200",
          }}
          fontSize="sm"
        >
          {error}
        </chakra.p>
      </Box>
    </Box>
  </Flex>
)
  

  
  return (
    <Box px={4} py={32} mx="auto" background={"#b72121"}>
      <Box
        w={{
          base: "full",
          md: 11 / 12,
          xl: 8 / 12,
        }}
        textAlign={{
          base: "left",
          md: "center",
        }}
        mx="auto"
      >
        <chakra.h1
          mb={3}
          fontSize={{
            base: "3xl",
            md: "5xl",
          }}
          fontWeight={{
            base: "bold",
            md: "bold",
          }}
          color="white"
          _dark={{
            color: "gray.100",
          }}
          lineHeight="shorter"
        >
          Youtube Video Downloader
        </chakra.h1>
        <chakra.p
          mb={6}
          fontSize={{
            base: "lg",
            md: "xl",
          }}
          color="white"
          lineHeight="base"
        >
         Download youtube videos, convert Youtube video to mp3/mp4 for free
         Download your favorite youtube videos in 480p,720p,1080p and  4k  
        </chakra.p>
        <SimpleGrid
          as="form"
          w={{
            base: "full",
            md: 7 / 12,
          }}
          columns={{
            base: 1,
            lg: 6,
          }}
          spacing={3}
          pt={1}
          mx="auto"
          mb={8}
        >
          <GridItem
            as="label"
            colSpan={{
              base: "auto",
              lg: 4,
            }}
          >
            <VisuallyHidden>Youtube Video Link</VisuallyHidden>
            <Input
              mt={0}
              size="lg"
              type="text"
              placeholder="Enter Youtube video link..."
              required
              value = {ytLink}
              onChange={(e)=>setytLink(e.target.value)}
              background={"#FFFFFF"}
            />
            
          </GridItem>
          
          <Button
            as={GridItem}
            w="full"
            variant="solid"
            colSpan={{
              base: "auto",
              lg: 2,
            }}
            size="lg"
            type="submit"
            colorScheme="brand"
            cursor="pointer"
            onClick={ytDownBtnClick}
          >
            Download
          </Button>
          
        </SimpleGrid>
        
       <Loading/>
      </Box>
    </Box>
  );
};

export default  Downloader