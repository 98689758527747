import Header from "./header";
import Footer from "./footer";



import React from "react";
import {
  chakra,
  Box,
  Button,SimpleGrid,Stack,GridItem,Input,Heading, Text,FormControl,FormLabel,Textarea,FormHelperText
} from "@chakra-ui/react";


import { Container } from '@chakra-ui/react'

function ContactUs(){



    return (
        <>
        <Container maxW='7xl' pt={5}>
        <Header/>
        
        <Box
  bg="#"
  _dark={{
    bg: "#111",
  }}
  w="w-full"
  p={10}
>
  <Box>
    <SimpleGrid
      display={{
        base: "initial",
        md: "grid",
      }}
      columns={{
        md: 3,
      }}
      spacing={{
        md: 6,
      }}
    >
      <GridItem
        colSpan={{
          md: 1,
        }}
      >
        <Box px={[4, 0]}>
          <Heading fontSize="lg" fontWeight="md" lineHeight="6">
            Contact Us
          </Heading>
          <Text
            mt={1}
            fontSize="sm"
            color="gray.600"
            _dark={{
              color: "gray.400",
            }}
          >
           You can reach us at shaonreverse@gmail.com. 
          </Text>
        </Box>
      </GridItem>
      <GridItem
        mt={[5, null, 0]}
        colSpan={{
          md: 2,
        }}
      >
        <chakra.form
          method="POST"
          shadow="base"
          rounded={[null, "md"]}
          overflow={{
            sm: "hidden",
          }}
        >
          <Stack
            px={4}
            py={5}
            bg="white"
            _dark={{
              bg: "#141517",
            }}
            spacing={6}
            p={{
              sm: 6,
            }}
          >
     

            <div>
             
              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="first_name"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  First name
                </FormLabel>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="given-name"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  size="sm"
                  w="full"
                  rounded="md"
                />
              </FormControl>

              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="last_name"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  Last name
                </FormLabel>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="family-name"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  size="sm"
                  w="full"
                  rounded="md"
                />
              </FormControl>

              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="email"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  Email address
                </FormLabel>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  size="sm"
                  w="full"
                  rounded="md"
                />
              </FormControl>

              <FormControl id="description" mt={1}>
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  Description 
                </FormLabel>
                <Textarea
                  placeholder="Your thoughts"
                  mt={1}
                  rows={3}
                  shadow="sm"
                  focusBorderColor="brand.400"
                  fontSize={{
                    sm: "sm",
                  }}
                />
                <FormHelperText>
                  Please fillup your information
                </FormHelperText>
              </FormControl>
            </div>

           
          </Stack>
          <Box
            px={{
              base: 4,
              sm: 6,
            }}
            py={3}
            bg="gray.50"
            _dark={{
              bg: "#121212",
            }}
            textAlign="right"
          >
            <Button
              type="submit"
              colorScheme="brand"
              _focus={{
                shadow: "",
              }}
              fontWeight="md"
            >
              Send mail
            </Button>
          </Box>
        </chakra.form>
      </GridItem>
    </SimpleGrid>
  </Box>

  </Box>
  
        <Footer/>
        </Container>
        </>
    )


}

export default ContactUs